<template>
  <div class="quest">
    <div @click="removePopup()" class="quest__close">
    </div>
    <div class="quest-name text-sm-h4 text-h5 text-md-h3 text-lg-h2 my-5 my-lg-10 text-center" style="max-width: 65%">
      {{ question ? question : 'Спасибо за ответы! Укажите Ваше имя и телефон для получения консультации адвоката (с анализом реальных перспектив Вашего дела)' }}
    </div>
    <div class="quest-wrap-btn mt-8">
      <v-radio
        color="#fff"
        v-for="button in buttons"
        :key="button"
        :label="button"
        :value="button"
        @click="result(question + ' : ' + button + '<br>')"
        dark
        class="my-3 text-sm-h5 text-h6 text-md-h4 text-lg-h4"
      ></v-radio>
    </div>
    <div v-if="1+1 === 3" class="btn-wrapper">
      <div
        v-for="button in buttons"
        :key="button"
        @click="result(question + ' : ' + button + '<br>')"
        class="quest__btn btn"
      >{{ button }}</div>
    </div>
    <div
      class="quest-images align-center d-flex flex-column"
      v-if="isQuest === 4"
      style="width: 70%"
    >
      <v-text-field v-model="article" dark color="white" label="Введите статью" style="max-width: 300px; width: 250px" class=""/>
      <v-btn
        rounded
        color="yellow"
        class="black--text mt-12"
        style="font-size: 20px"
        width="250px"
        height="50px"
        @click="result('статья №: ' + article + '<br>')"
      >
        Далее
      </v-btn>
    </div>
    <div
      class="form" v-if="isQuest === 55"
      style="width: 50%"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-form v-model="valid">
            <v-text-field
              v-model="form.city"
              label="Ваш город"
              :rules="nameRules"
              hide-details="auto"
              full-width
              color="#fff"
              dark
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <div class="btn-wrapper">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="yellow"
              class="black--text mt-10 float-right"
              width="150px"
              @click="valid ? result(form.city) : false"
            >
              Далее
            </v-btn>
          </template>
          <span>{{!valid?'Укажите город!':'Продолжить'}}</span>
        </v-tooltip>
      </div>
    </div>
    <div
      class="form" v-if="isQuest === 5"
      style="width: 50%"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-form v-model="validForm">
            <v-text-field
              v-model="form.email"
              label="Имя"
              hide-details="auto"
              full-width
              color="#fff"
              dark
            ></v-text-field>
            <v-text-field
              v-model="form.phone"
              label="Телефон"
              :rules="phoneRules"
              hide-details="auto"
              full-width
              color="#fff"
              dark
              return-masked-value
              v-mask="'+#(###)-###-##-##'"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <div class="btn-wrapper">
        <v-tooltip bottom v-if="!statusSend">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="yellow"
              class="black--text mt-10 float-right"
              width="150px"
              @click="validForm ? result(form) : false"
              onclick="ym(87176674,'reachGoal','order')"
            >
              Отправить
            </v-btn>
          </template>
          <span>{{!validForm?'Заполните данные!':'Продолжить'}}</span>
        </v-tooltip>
        <v-btn
          v-if="statusSend"
          rounded
          color="yellow"
          class="black--text mt-10 float-right"
          width="150px"
          @click="validForm ? result(form) : false"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </v-btn>
      </div>
    </div>
    <div v-if="1+1===3" class="quest-name">Спасибо! Заявка принята, ожидайте звонка
      <p class="h3">Наши контакты:</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quest',
  props: {
    question: String,
    buttons: Array,
    titleImages: Array,
    isQuest: Number,
    statusSend: Boolean
  },
  emits: ['removePopup', 'result', 'nextQuest', 'sendForm'],
  data () {
    return {
      form: {
        name: null,
        email: null,
        city: null,
        phone: null
      },
      // images: [
      //   { name: 'Ст. 158 УК РФ ', img: '1.jpg' },
      //   { name: 'Ст. 159 УК РФ', img: '2.jpg' },
      //   { name: 'Ст. 160 УК РФ', img: '3.jpg' },
      //   { name: 'Ст. 161 УК РФ', img: '4.jpg' },
      //   { name: 'Ст. 264 УК РФ', img: '7.jpg' },
      //   { name: 'Ст. 315 УК РФ', img: '5.jpg' },
      //   { name: 'Ст. 328 УК РФ', img: '6.jpg' }
      // ],
      article: null,
      nameRules: [
        v => !!v || 'Введите город'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (value && value.length >= 17) || 'Введите не менее 11 цифр'
        // value => (Boolean(Number(value))) || 'Введите только цифры'
      ],
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ],
      valid: false,
      validForm: false
    }
  },
  methods: {
    removePopup () {
      this.$emit('removePopup')
    },
    async result (button) {
      await this.$emit('result', button)
      this.$emit('nextQuest')
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.v-input--selection-controls__input {
  input {
    opacity: 0;
  }
}
.quest {
  min-width: 100%;
  color: #ffffff!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  .quest-name {
  }
  .quest-wrap-btn {
    display: flex;
    flex-direction: column;
  }
  .input-wrap {
    width: 100%;
  }
}
</style>
