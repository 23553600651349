var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"home-head d-flex justify-space-between pt-5"},[_c('img',{attrs:{"width":"300px","src":require("../assets/logo.png"),"alt":""}}),_c('div',[_c('router-link',{attrs:{"to":"/wins"}},[_c('v-btn',{staticClass:"white--text",attrs:{"text":""}},[_vm._v(" Выигранные дела ")])],1),_c('router-link',{attrs:{"to":"/resheniya"}},[_c('v-btn',{attrs:{"color":"#fff","text":""},on:{"click":function($event){_vm.drawer = false}}},[_vm._v(" Узнать шансы по делу ")])],1),_c('router-link',{attrs:{"to":"/zakonodatelstvo"}},[_c('v-btn',{attrs:{"color":"#fff","text":""}},[_vm._v(" Нюансы законодательства ")])],1)],1)]),_vm._m(0),_c('div',{staticClass:"wrap d-flex flex-column align-center"},[_vm._m(1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text mt-10",staticStyle:{"font-size":"20px"},attrs:{"rounded":"","color":"yellow","width":"250px","height":"50px"}},'v-btn',attrs,false),on),[_vm._v(" Начать тест ")])]}}]),model:{value:(_vm.dialog.test),callback:function ($$v) {_vm.$set(_vm.dialog, "test", $$v)},expression:"dialog.test"}},[_c('v-card',{staticClass:"test-wrap"},[_c('div',{staticClass:"test-wrap-progress d-flex align-center py-5 py-sm-7 py-md-10 py-lg-12"},[_c('div',{staticClass:"test-progress"},[_c('span',{style:({
                  left: ((_vm.progressValue -2) + "%")
                })},[_vm._v(" "+_vm._s(_vm.progressValue)+"% ")]),_c('v-progress-linear',{staticClass:"mt-8",attrs:{"color":"white","value":_vm.progressValue}})],1),(1===2)?_c('v-btn',{staticClass:"ml-10",attrs:{"icon":"","text":"","color":"white"},on:{"click":function($event){_vm.dialog.test = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),(_vm.isQuest === 1)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"Кем Вы являетесь?","buttons":[
                    'Подозреваемый',
                    'Обвиняемый',
                    'Потерпевший' ]},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 2)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"В какой стадии дело?","buttons":[
              'В следственных органах',
              'Вынесен приговор',
              'В ожидании суда'
                  ]},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 3)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"Есть ли материалы дела?","buttons":[
              'Да, есть',
              'Нет' ]},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 4)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"question":"Статья привлечения к уголовному делу?"},on:{"result":_vm.resultPush}}):_vm._e(),(_vm.isQuest === 5)?_c('quest',{attrs:{"is-quest":_vm.isQuest,"statusSend":_vm.statusSend},on:{"result":_vm.send}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog.successSend),callback:function ($$v) {_vm.$set(_vm.dialog, "successSend", $$v)},expression:"dialog.successSend"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-3 green lighten-2 white--text"},[_vm._v(" Заявка отправлена "),_c('v-icon',{staticClass:"ml-3",attrs:{"color":"white"}},[_vm._v("mdi-check-all")])],1),_c('v-card-text',[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v("Спасибо! Заявка принята, ожидайте звонка")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":function($event){_vm.dialog.successSend = false}}},[_vm._v(" Ок ")])],1)],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-auto d-table mr-4"},[_c('a',{staticClass:"white--text",staticStyle:{"font-size":"20px"},attrs:{"href":"tel:+79264080006"}},[_vm._v("Тел: +7(926)408-00-06")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap-title text-sm-h5 text-h6 text-md-h4 text-lg-h4"},[_vm._v(" Узнайте за 3 минуты"),_c('br'),_vm._v(" вероятность решения уголовного дела в вашу пользу! "),_c('br'),_c('br'),_vm._v(" Пройдите тест из 5 вопросов "),_c('br'),_vm._v(" Получите персональную консультацию адвоката с опытом "),_c('br'),_vm._v(" 11 лет в уголовных делах ")])}]

export { render, staticRenderFns }